import React, { useMemo } from 'react'
import { FormControl } from '@customink/pigment-react'
import FormGroup from '@/react/components/FormGroup'
import SelectInput from '@/react/components/SelectInput'
import TextInput from '@/react/components/TextInput'
import { Address, AddressError } from '@/types/address'
import { Contact, ContactError } from '@/types/contact'
import {
  getCountryOptions,
  getStates,
  isConus
} from '@/react/services/addressHelpers'

export type AddressFormProps = {
  address?: Address
  contact?: Contact
  errors?: AddressError & ContactError
  phoneInput?: React.ReactElement
  shippingCountryOptions?: string[]
  readonly?: boolean
  taxFieldsReadonly?: boolean
  handleBlur: (data: Partial<Address | Contact>) => void
  handleChange: (address: Partial<Address | Contact>) => void
}

const AddressForm = ({
  address,
  errors,
  phoneInput,
  shippingCountryOptions,
  readonly,
  taxFieldsReadonly,
  handleBlur,
  handleChange
}: AddressFormProps) => {
  const renderPhoneInput = useMemo(() => {
    if (!phoneInput) {
      return <></>
    }
    return (
      <FormGroup className="lg:flex-nowrap flex-wrap">{phoneInput}</FormGroup>
    )
  }, [phoneInput])
  return (
    <FormControl data-testid="AddressForm" fullWidth>
      <FormGroup className="lg:flex-nowrap flex-wrap">
        <TextInput
          autoComplete="given-name"
          className="lg:w-1/2 w-full"
          disabled={!!readonly}
          errorMessage={errors?.first_name}
          id="address-first-name"
          label="First Name"
          name="first_name"
          placeholder="Enter your first name"
          propertyName="first_name"
          required={true}
          value={address?.first_name || ''}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        <TextInput
          autoComplete="family-name"
          className="lg:w-1/2 lg:ml-5 w-full ml-0"
          disabled={!!readonly}
          errorMessage={errors?.last_name}
          id="address-last-name"
          label="Last Name"
          name="last_name"
          placeholder="Enter your last name"
          propertyName="last_name"
          required={true}
          value={address?.last_name || ''}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </FormGroup>
      <FormGroup className="flex-nowrap">
        <TextInput
          autoComplete="street-address"
          className="w-2/3"
          disabled={!!readonly}
          maxLength={35}
          errorMessage={errors?.address_1}
          id="address-street-1"
          label="Street Address"
          name="address_1"
          placeholder={'Enter number & street name'}
          propertyName="address_1"
          required={true}
          value={address?.address_1 || ''}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        <TextInput
          disabled={!!readonly}
          maxLength={35}
          className="w-1/3 ml-5"
          errorMessage={errors?.address_2}
          id="address-strees-2"
          label="Apt. / Suite"
          name="address_2"
          placeholder="Apt. / Suite"
          propertyName="address_2"
          value={address?.address_2 || ''}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </FormGroup>
      <FormGroup className="flex-nowrap">
        <TextInput
          autoComplete="address-level2"
          className="w-2/3"
          disabled={!!readonly}
          maxLength={30}
          errorMessage={errors?.city}
          id="address-city"
          label="City"
          name="city"
          placeholder="City"
          propertyName="city"
          required={true}
          value={address?.city || ''}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        <TextInput
          className="w-1/3 ml-5"
          disabled={!!readonly || !!taxFieldsReadonly}
          maxLength={9}
          errorMessage={errors?.postal_code}
          id="address-postal-code"
          label="Postal Code"
          name="postal_code"
          placeholder="Postal code"
          propertyName="postal_code"
          required={isConus(address?.country)}
          value={address?.postal_code || ''}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </FormGroup>
      <FormGroup className="lg:flex-nowrap flex-wrap">
        <SelectInput
          autoComplete="country"
          className="lg:w-1/2 w-full"
          disabled={!!readonly || !!taxFieldsReadonly}
          defaultValue="Select"
          errorMessage={errors?.country}
          label="Country"
          name="country"
          options={getCountryOptions(shippingCountryOptions).map((country) => ({
            label: country.name,
            value: country.code
          }))}
          placeholder={'Select a country'}
          propertyName="country"
          required={true}
          value={address?.country}
          onBlur={handleBlur}
          onChange={(data) =>
            handleChange({
              ...data,
              ...{ state: undefined }
            })
          }
        />
        <SelectInput
          autoComplete="address-level1"
          className="lg:w-1/2 lg:ml-5 w-full ml-0"
          disabled={!!readonly || !!taxFieldsReadonly || !address?.country}
          defaultValue="Select"
          errorMessage={errors?.state}
          label="State / Province"
          name="state"
          options={getStates(address).map((stateInfo) => ({
            label: stateInfo.name,
            value: stateInfo.code
          }))}
          placeholder={'Select a state'}
          propertyName="state"
          required={isConus(address?.country)}
          value={address?.state || ''}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </FormGroup>
      {renderPhoneInput}
    </FormControl>
  )
}

export default AddressForm
